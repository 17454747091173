@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar:horizontal {
  height: 12px; 
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: var(--ui-primary);
  border-radius: 10px;
  border: 3px solid #f1f1f1;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

@mixin auth-form-layout() {
  width: 100%;
  background-color: var(--ui-white);
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 40px;
}

@mixin vertical-display-layout($gap) {
  display: flex;
  flex-direction: column;
  gap: $gap;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-top: 1rem;
  margin-bottom: 1rem;

  & > .col {
    padding-right: calc(1.5rem * 0.5);
    padding-left: calc(1.5rem * 0.5);
  }
}

.mb {
  &-tiny {
    margin-bottom: 8px;
  }
}

.mt {
  &-tiny {
    margin-top: 8px;
  }
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.zawaj-input {
  background-color: transparent;
  border-radius: 20px;
  padding-inline: 8px;
  width: 100%;
  border: 1px solid #000000;
  height: 41px;
  padding: 0 1rem;
  outline: 1px solid #000;

  &:focus {
    outline: 2px solid var(--ui-tertiary);
    border-color: var(--ui-tertiary);
  }

  &.has-errors {
    outline-color: var(--ui-primary);
    border-color: var(--ui-primary);
  }
}

textarea.zawaj-input {
  height: unset;
  min-height: 90px;
  padding-top: 1rem;
  padding-bottom: 0.5rem;
}

.text-area {
  .text-area-label {
    padding-left: 10px;
    margin-bottom: 10px;
  }

  .text-area-input {
    width: 100%;
    border-radius: 10px;
    padding: 10px;
    resize: none;

    &:focus {
      border-color: var(--ui-primary);
    }
  }

  &.error {
    .text-area-label {
      color: var(--ui-error);
    }
    .text-area-input {
      border-color: var(--ui-error);
    }
    p {
      color: var(--ui-error);
    }
  }
}

.daterangepicker .ranges li.active {
  background-color: var(--ui-primary) !important;
  color: #fff;
}

.daterangepicker td.active,
.daterangepicker td.active:hover {
  background-color: var(--ui-primary) !important;
  border-color: transparent;
  color: #fff;
}


